define('ember-hifi/hifi-connections/dummy-connection', ['exports', 'ember-hifi/hifi-connections/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var ClassMethods = Ember.Mixin.create({
    setup: function setup() {},

    canPlay: function canPlay() {
      return true;
    },
    canUseConnection: function canUseConnection() {
      return true;
    },
    canPlayMimeType: function canPlayMimeType() {
      return true;
    },
    toString: function toString() {
      return 'Dummy Connection';
    }
  });

  var DummyConnection = _base.default.extend({
    debugName: 'dummyConnection',
    _position: 0,
    _tickInterval: 50,
    setup: function setup() {
      var _this = this;

      var _getInfoFromUrl = this.getInfoFromUrl(),
          result = _getInfoFromUrl.result;

      if (result === 'bad') {
        Ember.run.next(function () {
          return _this.trigger('audio-load-error', _this);
        });
      } else {
        Ember.run.next(function () {
          return _this.trigger('audio-ready', _this);
        });
      }
    },


    stopTicking: function stopTicking() {
      window.clearTimeout(this.tick);
    },

    startTicking: function startTicking() {
      var _this2 = this;

      if (!Ember.Test.checkWaiters || Ember.Test.checkWaiters()) {
        this.tick = window.setTimeout(Ember.run.bind(function () {
          _this2._setPosition((_this2._currentPosition() || 0) + _this2.get('_tickInterval'));
          _this2.startTicking();
        }), this.get('_tickInterval'));
      } else {
        this.stopTicking();
      }
    },

    getInfoFromUrl: function getInfoFromUrl() {
      if (!this.get('url')) {
        return {};
      } else if (this.get('url').startsWith('/')) {
        var _get$split = this.get('url').split('/'),
            _get$split2 = _slicedToArray(_get$split, 4),
            result = _get$split2[1],
            length = _get$split2[2],
            name = _get$split2[3];

        /*eslint no-console: 0 */
        if (!(result && length && name)) {
          console.error('[dummy-connection] url format should be "/:result/:length/:name"');
        } else {
          if (!(length === 'stream' || parseInt(length) > 0)) {
            console.error('[dummy-connection] url format should be "/:result/:length/:name"');
            console.error('[dummy-connection] length should be an integer or "stream". Was given ' + this.get('url'));
          }

          if (!(result === 'good' || result === 'bad')) {
            console.error('[dummy-connection] url format should be "/:result/:length/:name"');
            console.error('[dummy-connection] status should be \'good\' or \'bad\'. Was given ' + this.get('url'));
          }
        }

        return { result: result, length: length, name: name };
      } else {
        return { result: 'good', length: 1000, name: 'default' };
      }
    },

    play: function play() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          position = _ref.position;

      if (typeof position !== 'undefined') {
        this.set('_position', position);
      }
      this.trigger('audio-played', this);
      this.startTicking();
    },
    pause: function pause() {
      this.trigger('audio-paused', this);
      this.stopTicking();
    },
    stop: function stop() {
      this.trigger('audio-paused', this);
      this.stopTicking();
    },
    _setPosition: function _setPosition(duration) {
      var _this3 = this;

      duration = Math.max(0, duration);
      duration = Math.min(this._audioDuration(), duration);
      this.set('_position', duration);

      if (duration >= this._audioDuration()) {
        Ember.run.next(function () {
          _this3.trigger('audio-ended', _this3);
          _this3.stopTicking();
        });
      }

      return duration;
    },
    _currentPosition: function _currentPosition() {
      return this.get('_position');
    },
    _setVolume: function _setVolume(v) {
      this.set('volume', v);
    },
    _audioDuration: function _audioDuration() {
      var _getInfoFromUrl2 = this.getInfoFromUrl(),
          result = _getInfoFromUrl2.result,
          length = _getInfoFromUrl2.length;

      if (result === 'bad') {
        return;
      }

      if (length === 'stream') {
        return Infinity;
      } else {
        return parseInt(length, 10);
      }
    }
  });

  DummyConnection.reopenClass(ClassMethods);

  exports.default = DummyConnection;
});