define('ember-hifi/hifi-connections/howler', ['exports', 'ember-hifi/hifi-connections/base', 'howler'], function (exports, _base, _howler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ClassMethods = Ember.Mixin.create({
    rejectMimeTypes: ['application/vnd.apple.mpegurl'],

    toString: function toString() {
      return 'Howler';
    }
  });

  var Sound = _base.default.extend({
    setup: function setup() {
      var urls = Ember.makeArray(this.get('url'));
      var sound = this;
      var options = Object.assign({
        src: urls,
        autoplay: false,
        preload: true,
        html5: true,
        volume: 1,
        onload: function onload() {
          sound.set('url', this._src);
          sound.set('howl', this);
          sound.trigger('audio-loaded', sound);
          sound.trigger('audio-ready', sound);
        },
        onpause: function onpause() {
          sound.trigger('audio-paused', sound);
        },
        onplay: function onplay() {
          sound.trigger('audio-played', sound);
        },
        onend: function onend() {
          sound.trigger('audio-ended', sound);
        },
        onstop: function onstop() {
          sound.trigger('audio-paused', sound);
        },
        onloaderror: function onloaderror(id, error) {
          sound.trigger('audio-load-error', error);
        },
        onseek: function onseek() {
          sound.trigger('audio-position-changed', sound._currentPosition());
        }
      }, this.get('options'));

      new _howler.Howl(options);
    },
    teardown: function teardown() {
      var howl = this.get('howl');
      if (howl) {
        howl.unload();
      }
    },
    _audioDuration: function _audioDuration() {
      return this.get('howl').duration() * 1000;
    },
    _currentPosition: function _currentPosition() {
      return this.get('howl').seek() * 1000;
    },
    _setPosition: function _setPosition(position) {
      this.get('howl').seek(position / 1000);
      return this._currentPosition();
    },
    _setVolume: function _setVolume(volume) {
      this.get('howl').volume(volume / 100);
    },
    play: function play() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          position = _ref.position;

      if (typeof position !== 'undefined') {
        this._setPosition(position);
      }
      this.get('howl').play();
    },
    pause: function pause() {
      this.get('howl').pause();
    },
    stop: function stop() {
      this.get('howl').stop();
    }
  });

  Sound.reopenClass(ClassMethods);

  exports.default = Sound;
});