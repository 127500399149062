define('ember-hifi/hifi-connections/hls', ['exports', 'ember-hifi/hifi-connections/base', 'hls'], function (exports, _base, _hls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ClassMethods = Ember.Mixin.create({
    acceptMimeTypes: ['application/vnd.apple.mpegurl'],

    canUseConnection: function canUseConnection() /* audioUrl */{
      // We basically never want to use this on a mobile device
      return _hls.default.isSupported();
    },
    toString: function toString() {
      return 'HLS.js';
    }
  });

  var Sound = _base.default.extend({
    loaded: false,
    mediaRecoveryAttempts: 0,

    setup: function setup() {
      var hls = new _hls.default({ debug: false, startFragPrefetch: true });
      var video = document.createElement('video');

      this.set('video', video);
      this.set('hls', hls);
      hls.attachMedia(video);
      this._setupHLSEvents(hls);
      this._setupPlayerEvents(video);
    },
    _setupHLSEvents: function _setupHLSEvents(hls) {
      var _this = this;

      hls.on(_hls.default.Events.MEDIA_ATTACHED, function () {
        _this.debug('media attached');
        hls.loadSource(_this.get('url'));

        hls.on(_hls.default.Events.MANIFEST_PARSED, function (e, data) {
          _this.debug('manifest parsed and loaded, found ' + data.levels.length + ' quality level(s)');
          _this.set('manifest', data);
        });

        hls.on(_hls.default.Events.LEVEL_LOADED, function (e, data) {
          _this.debug('level ' + data.level + ' loaded');
          _this._checkIfAudioIsReady();
        });

        hls.on(_hls.default.Events.AUDIO_TRACK_LOADED, function () {
          _this.debug('audio track loaded');
          _this._checkIfAudioIsReady();
        });

        hls.on(_hls.default.Events.ERROR, function (e, data) {
          return _this._onHLSError(e, data);
        });
      });
    },
    _setupPlayerEvents: function _setupPlayerEvents(video) {
      var _this2 = this;

      Ember.$(video).on('playing', function () {
        if (_this2.get('loaded')) {
          _this2.trigger('audio-played', _this2);
        } else {
          _this2._signalAudioIsReady();
        }
      });

      Ember.$(video).on('pause', function () {
        return _this2.trigger('audio-paused', _this2);
      });
      Ember.$(video).on('durationchange', function () {
        return _this2.trigger('audio-duration-changed', _this2);
      });
      Ember.$(video).on('seeked', function () {
        return _this2.trigger('audio-position-changed', _this2);
      });
      Ember.$(video).on('progress', function () {
        return _this2.trigger('audio-loading');
      });
      Ember.$(video).on('error', function (e) {
        return _this2._onVideoError(e);
      });
    },
    _checkIfAudioIsReady: function _checkIfAudioIsReady() {
      if (!this.get('loaded')) {
        // The only reliable way to check if this thing is actually ready
        // is to play it. If we get a play signal we're golden, but if we
        // get an error, we're outta here

        this.debug('Testing if audio is ready');
        this.get('video').volume = 0;
        this.get('video').play();
      }
    },
    _signalAudioIsReady: function _signalAudioIsReady() {
      this.debug('Test succeeded, signaling audio-ready');
      this.set('loaded', true);
      this.get('video').pause();
      this.trigger('audio-ready');
    },
    _onVideoError: function _onVideoError(e) {
      switch (e.target.error.code) {
        case e.target.error.MEDIA_ERR_ABORTED:
          this.debug("video element error: playback aborted");
          this._giveUpAndDie("unknown error");
          break;
        case e.target.error.MEDIA_ERR_NETWORK:
          this.debug("video element error: network error");
          this._giveUpAndDie("Network error caused download to fail");
          break;
        case e.target.error.MEDIA_ERR_DECODE:
          this.debug("video element error: decoding error");
          this._tryToRecoverFromMediaError(e.target.error.MEDIA_ERR_DECODE);
          break;
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          this.debug("video element error: source format not supported");
          this._giveUpAndDie("audio source format is not supported");
          break;
        default:
          this._giveUpAndDie("unknown error");
          break;
      }
    },
    _onHLSError: function _onHLSError(error, data) {
      if (data.fatal) {
        switch (data.type) {
          case _hls.default.ErrorTypes.NETWORK_ERROR:
            this.debug(data);
            this._giveUpAndDie('' + data.details);
            break;
          case _hls.default.ErrorTypes.MEDIA_ERROR:
            this._tryToRecoverFromMediaError('' + data.details);
            break;
          default:
            this._giveUpAndDie('' + data.details);
            break;
        }
      }
    },
    _tryToRecoverFromMediaError: function _tryToRecoverFromMediaError(error) {
      var mediaRecoveryAttempts = this.get('mediaRecoveryAttempts');
      var hls = this.get('hls');

      switch (mediaRecoveryAttempts) {
        case 0:
          this.debug('First attempt at media error recovery for error: ' + error);
          hls.recoverMediaError();
          break;
        case 1:
          this.debug('Second attempt at media error recovery: switching codecs for error: ' + error);
          hls.swapAudioCodec();
          hls.recoverMediaError();
          break;
        case 2:
          this.debug('We tried our best and we failed: ' + error);
          this._giveUpAndDie(error);
          break;
      }

      this.incrementProperty('mediaRecoveryAttempts');
    },
    _giveUpAndDie: function _giveUpAndDie(error) {
      this.get('hls').destroy();
      this.trigger('audio-load-error', error);
    },
    _audioDuration: function _audioDuration() {
      return Infinity; // only streams
    },
    _currentPosition: function _currentPosition() {
      return this.get('video').currentTime;
    },
    _setPosition: function _setPosition(position) {
      this.get('video').currentTime = position;
    },
    _setVolume: function _setVolume(volume) {
      this.get('video').volume = volume / 100;
    },
    play: function play() {
      this.get('video').play();

      if (this.get('loadStopped')) {
        this.get('hls').startLoad();
        this.set('loadStopped', false);
      }
    },
    pause: function pause() {
      this.stop(); // We always want to stop the stream so it doesn't continue to download
    },
    stop: function stop() {
      this.get('video').pause();
      this.get('hls').stopLoad();
      this.set('loadStopped', true);
    },
    teardown: function teardown() {
      this.get('hls').destroy();
    }
  });

  Sound.reopenClass(ClassMethods);

  exports.default = Sound;
});