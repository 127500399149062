define('ember-hifi/utils/debug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Debug = function () {
    function Debug(name) {
      _classCallCheck(this, Debug);

      this.name = name;
      this.color = this._createColor();
      this.padLength = 25;
      this.timerStore = [];
    }

    /**
    * Logs some content in a pretty fromat
    * @param  {string} content - Content to log
    */

    _createClass(Debug, [{
      key: 'log',
      value: function log(content) {
        if (window.console && window.console.log) {
          var colorString = 'color: ' + this.color + '; font-weight: bold;';
          var name = this.name.slice(0, this.padLength);
          var titleContent = Array(this.padLength + 3 - name.length).join(' ');
          if (this._isIE() || !!window.callPhantom) {
            // IE's console isn't so great. Make this plain.
            var title = '' + name + titleContent + ' | ' + content;
            console.log(title); // eslint-disable-line no-console
          } else {
            var _title = '%c' + name + titleContent + ' | ';
            console.log(_title, colorString, content); // eslint-disable-line no-console
          }
        }
      }
    }, {
      key: 'timeEnd',
      value: function timeEnd(name) {
        var start = Date.now();
        var foundIndex = void 0;

        var runningTimer = this.timerStore.find(function (item, index) {
          if (item.name === name) {
            foundIndex = index;
            return true;
          }
        });

        if (runningTimer) {
          this.timerStore.splice(foundIndex, 1);
          this.log(name + ' took ' + (start - runningTimer.start) + 'ms');
        }
      }
    }, {
      key: 'time',
      value: function time(name) {
        this.timerStore.push({ start: Date.now(), name: name });
      }
    }, {
      key: '_createColor',
      value: function _createColor() {
        var h = this._random(1, 360);
        var s = this._random(60, 100);
        var l = this._random(0, 50);
        return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
      }
    }, {
      key: '_random',
      value: function _random(min, max) {
        return min + Math.random() * (max - min);
      }
    }, {
      key: '_isIE',
      value: function _isIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
          // Edge (IE 12+) => return version number
          return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
      }
    }]);

    return Debug;
  }();

  exports.default = Debug;
});